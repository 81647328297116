import logoImage from './assets/images/logo.webp';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/protectedRoute';
import {Login} from './components/login'
import {Dashboard} from './components/dashboard'


function App() {
    return (
    <div class="fitchallenge">
      <header>
            <div className="c-header__logo">
            <img src={logoImage} alt="logo" />
            </div>
      </header>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
