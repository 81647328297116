import React from 'react';
import { Navigate } from 'react-router-dom';

function isAuthenticated() {
  const token = localStorage.getItem('userToken');
  return !!token;
}

function ProtectedRoute({ children }) {
  const isAuth = isAuthenticated();

  return isAuth ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;