import anaImage from '../assets/images/ana.png';
import adriImage from '../assets/images/adri.png';
import logoImage from '../assets/images/logo.webp';
import crownImage from '../assets/images/crown.webp';
import infoImage from '../assets/images/info.webp';
// import dataG from'./assets/data/data.json';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area } from 'recharts';
import * as React from 'react';
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import {useState, useEffect} from 'react';

export const Dashboard = () => {
    const [data, setData] = useState({ nodes: [] });
  const [weightAna, setWeightAna] = useState(0);
  const [weightTotalAna, setWeightTotalAna] = useState(0);
  const [weightTotalAdri, setWeightTotalAdri] = useState(0);
  const [weightAdri, setWeightAdri] = useState(0);
  const [pointsAdri, setPointsAdri] = useState(0);
  const [pointsAna, setPointsAna] = useState(0);
  const [dataAna, setDataAna] = useState(0);
  const [dataAdri, setDataAdri] = useState(0);
  const [challengeAdri, setChallengeAdri] = useState(0);
  const [challengeAna, setChallengeAna] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [punishAdri, setPunishAdri] = useState(null);
  const [punishAna, setPunishAna] = useState(null);
  const [prizeAdri, setPrizeAdri] = useState(null);
  const [prizeAna, setPrizeAna] = useState(null);
  const [isActiveCrownAna, setIsActiveCrownAna] = useState(false);
  const [isActiveCrownAdri, setIsActiveCrownAdri] = useState(false);
  const [isActiveInfo, setIsActiveInfo] = useState(false);
  const [isActivePunishInfo, setIsActivePunishInfo] = useState(false);
  let nodes = [];
  let dataTA = { nodes };
  const fetchData = async () => {
    try {
      const response = await fetch('./data.json');
      if (!response.ok) {
        throw new Error('Error de red');
      }
      const jsonData = await response.json();
      setData(jsonData);

      const anaWeightData = jsonData.Ana.pesos;
      const adriWeightData = jsonData.Adri.pesos;
      console.log(jsonData);
      setWeightAna(anaWeightData[anaWeightData.length - 1].weight);
      setWeightAdri(adriWeightData[adriWeightData.length - 1].weight);
      setChallengeAna(jsonData.Ana.challenges);
      setChallengeAdri(jsonData.Adri.challenges);
      setDataAna(jsonData.Ana.pesos);
      setDataAdri(jsonData.Adri.pesos);
      nodes = jsonData.Ana.challenges;
      dataTA = { nodes };
      setData(dataTA);
      getCrownUser(jsonData);
      getPunishList(jsonData);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      console.log('jsonData',error.message);
      setIsLoading(false);
    }
  };

  const getCrownUser = (data) => {
    const anaWeightData = data.Ana.pesos;
    const adriWeightData = data.Adri.pesos;
    setWeightTotalAna(Math.round(anaWeightData[0].weight - anaWeightData[anaWeightData.length - 1].weight));
    setWeightTotalAdri(Math.round(adriWeightData[0].weight - adriWeightData[adriWeightData.length - 1].weight));
    let anaPoints = 0;
    let weightNewPointsAna = 86.9;
    const anaPointsWeight = weightNewPointsAna - anaWeightData[anaWeightData.length - 1].weight
    if (anaPointsWeight > 0) {
      anaPoints = anaPoints + anaPointsWeight;
    }
    for (var i = 0; i < data.Ana.challenges.length; i++){
      if (data.Ana.challenges[i].result === 'Ganado' && data.Ana.challenges[i].result === 3) {
        anaPoints = anaPoints + 10;
      }
    }
    setPointsAna(Math.round(anaPoints * 10) / 10);

    let adriPoints = 0;
    let weightNewPoints = 121.4;
    const adriPointsWeight = weightNewPoints - adriWeightData[adriWeightData.length - 1].weight
    if (adriPointsWeight > 0) {
      adriPoints = adriPoints + adriPointsWeight;
    }
    for (var i = 0; i < data.Adri.challenges.length; i++){
      if (data.Adri.challenges[i].result === 'Ganado' && data.Adri.challenges[i].period === 3) {
        adriPoints = adriPoints + 10;
      }
    }
    setPointsAdri(Math.round(adriPoints * 10) / 10);

    if (anaPoints > adriPoints) {
      setIsActiveCrownAna(true);
    } else {
      setIsActiveCrownAdri(true);
    }
  }

  const getPunishList = (data) => {
    const anaPunishData = data.Ana.challenges;
    const adriPunishData = data.Adri.challenges;

    let listAna = '';
    let listAdri = '';

    let listPrizeAna = '';
    let listPrizeAdri = '';

    for (var i = 0; i < anaPunishData.length; i++){
      if (anaPunishData[i].result === 'Fallido' && anaPunishData[i].charged === 'no') {
        listAna += `<span class="c-list-punish__item">${anaPunishData[i].punishment}</span>`;
      }

      if (anaPunishData[i].result === 'Ganado') {
        listPrizeAna += `<span class="c-list-punish__item">${anaPunishData[i].prize}</span>`;
      }
    }

    for (var i = 0; i < adriPunishData.length; i++){
      if (adriPunishData[i].result === 'Fallido' && adriPunishData[i].charged === 'no') {
        listAdri += `<span class="c-list-punish__item">${adriPunishData[i].punishment}</span>`;
      }
      if (adriPunishData[i].result === 'Ganado') {
        listPrizeAdri += `<span class="c-list-punish__item">${adriPunishData[i].prize}</span>`;
      }
    }

    setPunishAna(listAna);
    setPunishAdri(listAdri);
    setPrizeAna(listPrizeAna);
    setPrizeAdri(listPrizeAdri);
  }

  useEffect(() => {
    fetchData();
  }, []);
  // const [data, setData] = useState(dataTA);
  // const weightAna = dataG.Ana.pesos[Object.keys(dataG.Ana.pesos).length -1 ].weight;
  // const weightAdri = dataG.Adri.pesos[Object.keys(dataG.Adri.pesos).length -1 ].weight;
  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns:  25% 25% 50% 50% 40% 100px;
      `,
    },
  ]);
  const COLUMNS = [
    { label: 'Fecha', renderCell: (item) => item.date },
    { label: 'Reto gr', renderCell: (item) => item.weightLost },
    { label: 'Premio', renderCell: (item) => item.prize },
    { label: 'Castigo', renderCell: (item) => item.punishment },
    { label: 'Resultado', renderCell: (item) => item.result },
    { label: 'Cobrado', renderCell: (item) => item.charged },
  ];

  const [isActiveAna, setIsActive] = useState(false);
  const [isActiveAdri, setIsActiveAdri] = useState(false);

  const handleClickPoints = event => {
    setIsActiveInfo(current => !current);
  };

  const handleClickPunish = event => {
    setIsActivePunishInfo(current => !current);
  };

  const handleClick = event => {
    setIsActive(current => !current);
    setIsActiveAdri(current => false);
    nodes = challengeAna;
    dataTA = { nodes };
    console.log(dataTA);
    setData(dataTA);
  };

  const handleClickAdri = event => {
    setIsActiveAdri(current => !current);
    setIsActive(current => false);
    nodes = challengeAdri;
    dataTA = { nodes };
    console.log(dataTA);
    setData(dataTA);
  };

  const newChallengeAdri = () => {
    const weightOptions = ["250", "500", "750", "1000"];
    const painOptions = ["30 minutos masaje", "1 dia servidumbre", "Semana comiendo verde o naranja", "1 hora de masaje"];
    const prizeOptions = ["Una parte de arriba a elegir si vestir, deporte, casual o interior", "Una parte de abajo a elegir vestir, casual, deporte o interior", "Outif completo a elegir, vestir, casual o deporte", "Boxer"];

    const randomIndexW = Math.floor(Math.random() * weightOptions.length);
    const selectedOptionW = weightOptions[randomIndexW];
    const randomIndexPain = Math.floor(Math.random() * painOptions.length);
    const selectedOptionPain = painOptions[randomIndexPain];
    const randomIndexPrize = Math.floor(Math.random() * prizeOptions.length);
    const selectedOptionPrize = prizeOptions[randomIndexPrize];

    const fecha = new Date();
    const dia = fecha.getDate();
    const diaFormateado = dia < 10 ? '0' + dia : dia;
    const mes = fecha.getMonth() + 1;
    const mesFormateado = mes < 10 ? '0' + mes : mes;

    const newChallenge = {
      "id": Date.now(),
      "date": `${diaFormateado}/${mesFormateado}`,
      "weightLost": selectedOptionW,
      "prize": selectedOptionPrize,
      "punishment": selectedOptionPain,
      "result": "En proceso",
      "charged": "no"
    }
    console.log(newChallenge);

    fetch('save-data.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newChallenge)
    })
    .then(response => response.json())
    .then(data => {
      if (data.mensaje) {
        console.log(data.mensaje);
        challengeAdri.push(newChallenge);
        nodes = challengeAdri;
        dataTA = { nodes };
        setData(dataTA);
      } else {
        console.error('Error al agregar el usuario');
      }
    })
    .catch(error => {
      console.error('Error al agregar el usuario', error);
    });
  }

  const newChallengeAna = () => {
    const weightOptions = ["250", "500", "750", "1000"];
    const painOptions = ["30 minutos masaje", "1 dia servidumbre", "Semana comiendo verde o naranja", "invitarme a cenar en un sitio sano a tu elección"];
    const prizeOptions = ["Una parte de arriba a elegir si vestir, deporte, casual o interior", "Una parte de abajo a elegir vestir, casual, deporte o interior", "Outif completo a elegir, vestir, casual o deporte", "Tanga"];

    const randomIndexW = Math.floor(Math.random() * weightOptions.length);
    const selectedOptionW = weightOptions[randomIndexW];
    const randomIndexPain = Math.floor(Math.random() * painOptions.length);
    const selectedOptionPain = painOptions[randomIndexPain];
    const randomIndexPrize = Math.floor(Math.random() * prizeOptions.length);
    const selectedOptionPrize = prizeOptions[randomIndexPrize];

    const fecha = new Date();
    const dia = fecha.getDate();
    const diaFormateado = dia < 10 ? '0' + dia : dia;
    const mes = fecha.getMonth() + 1;
    const mesFormateado = mes < 10 ? '0' + mes : mes;

    const newChallenge = {
      "id": Date.now(),
      "date": `${diaFormateado}/${mesFormateado}`,
      "weightLost": selectedOptionW,
      "prize": selectedOptionPrize,
      "punishment": selectedOptionPain,
      "result": "En proceso",
      "charged": "no"
    }
    console.log(newChallenge);

    fetch('save-data-ana.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newChallenge)
    })
    .then(response => response.json())
    .then(data => {
      if (data.mensaje) {
        console.log(data.mensaje);
        challengeAna.push(newChallenge);
        nodes = challengeAna;
        dataTA = { nodes };
        setData(dataTA);
      } else {
        console.error('Error al agregar el usuario');
      }
    })
    .catch(error => {
      console.error('Error al agregar el usuario', error);
    });
  }

 if(isLoading) {
  return 'cargando';
 }

  return (
    <div>
      <header class="c-header__into-app">
        <h1>Estadísticas</h1>
        <p>Selecciona el usuario para ver sus estadísticas</p>
      </header>
      <ul className='c-tabs'>
        <li className={isActiveAna ? 'c-tabs__tab is-active' : 'c-tabs__tab'} onClick={handleClick}>
          <img src={anaImage} className='c-tabs__img' alt="Ana" />
          <img src={crownImage} className={isActiveCrownAna ? 'c-tabs__crown is-active' : 'c-tabs__crown'} alt="crown" />
          <span className='c-tabs__name'>Anika</span>
        </li>
        <li className={isActiveAdri ? 'c-tabs__tab is-active' : 'c-tabs__tab'} onClick={handleClickAdri}>
          <img src={adriImage} className='c-tabs__img' alt="Adri" />
          <img src={crownImage} className={isActiveCrownAdri ? 'c-tabs__crown is-active' : 'c-tabs__crown'} alt="crown" />
          <span className='c-tabs__name'>Adri</span>
        </li>
      </ul>
      <div className={isActiveInfo ? 'c-info is-active' : 'c-info'}>
        <button onClick={handleClickPoints} type="button" class="c-info__close">X</button>
        <h2>¿Cómo se puntua?</h2>
        <p>0.1 punto por cada 100 gramos perdidos.</p>
        <p>10 puntos por cada reto semanal conseguido.</p>
        <p>Al finalizar el reto trimestral el ganador obtendra un outfit completo a elegir como premio.</p>
        <p>Se mostrará una corona en el icono del usuario que va ganando el reto.</p>
      </div>
      <div className={isActivePunishInfo ? 'c-info is-active' : 'c-info'}>
        <button onClick={handleClickPunish} type="button" class="c-info__close">X</button>
        <h2>Listado de castigos disponibles</h2>
        <p>A continuación tienes un listado con los castigos que puedes utilizar a tu favor.</p>
        <p className={isActiveAna ? 'c-list-punish is-active' : 'c-list-punish'} dangerouslySetInnerHTML={{ __html: punishAdri }}></p>
        <p className={isActiveAdri ? 'c-list-punish is-active' : 'c-list-punish'} dangerouslySetInnerHTML={{ __html: punishAna }}></p>
        <h2>Listado de premios disponibles</h2>
        <p>A continuación tienes un listado con los premios que has ganado y recibirás cuando llegues a tu objetivo final. Ana 65kg | Adri 85kg</p>
        <p className={isActiveAna ? 'c-list-punish is-active' : 'c-list-punish'} dangerouslySetInnerHTML={{ __html: prizeAna }}></p>
        <p className={isActiveAdri ? 'c-list-punish is-active' : 'c-list-punish'} dangerouslySetInnerHTML={{ __html: prizeAdri }}></p>
      </div>
      <div className={isActiveAna ? 'c-tab-zone is-active' : 'c-tab-zone'} data-tab='ana'>
        <div className='c-heading-wrapper'>
          <h2>Peso Actual <span>{weightAna}</span></h2>
          <h2 onClick={handleClickPoints}>Puntos <span>{pointsAna}</span><img src={infoImage} className='c-tabs__info' alt="info" /></h2>
        </div>
        <p>Objetivo 1 Julio <span>69.0Kg</span> </p>
        <h2>Objeteivo semanal <span>{challengeAna[challengeAna.length - 1].weightLost}</span></h2>
        <div className='c-objective-wrapper'>
          <h3>Premio <span>{challengeAna[challengeAna.length - 1].prize}</span></h3>
          <h3>Castigo <span>{challengeAna[challengeAna.length - 1].punishment}</span></h3>
        </div>
        <p>Gráfica de peso</p>
        <AreaChart width={350} height={200} data={dataAna}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey="weight" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
        <p>Total peso perdido: <strong>{weightTotalAna} Kg</strong></p>
        <CompactTable columns={COLUMNS} data={data} theme={theme} layout={{ custom: true, horizontalScroll: true }}/>
        <button className='c-spin-btn' onClick={newChallengeAna}>Nuevo Reto Aleatorio</button>
        <button className='c-punish-btn' onClick={handleClickPunish}>Ver Castigos y Premios Disponnibles</button>
      </div>
      <div className={isActiveAdri ? 'c-tab-zone is-active' : 'c-tab-zone'} data-tab='adri'>
        <div className='c-heading-wrapper'>
          <h2>Peso Actual <span>{weightAdri}</span></h2>
          <h2 onClick={handleClickPoints}>Puntos <span>{pointsAdri}</span><img src={infoImage} className='c-tabs__info' alt="info" /></h2>
        </div>
        <p>Objetivo 1 Julio <span>99.0Kg</span> </p>
        <h2>Objeteivo semanal <span>{challengeAdri[challengeAdri.length - 1].weightLost}</span></h2>
        <div className='c-objective-wrapper'>
          <h3>Premio <span>{challengeAdri[challengeAdri.length - 1].prize}</span></h3>
          <h3>Castigo <span>{challengeAdri[challengeAdri.length - 1].punishment}</span></h3>
        </div>
        <p>Gráfica de peso</p>
        <AreaChart width={350} height={200} data={dataAdri}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey="weight" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
        <p>Total peso perdido: <strong>{weightTotalAdri} Kg</strong></p>
        <CompactTable columns={COLUMNS} data={data} theme={theme} layout={{ custom: true, horizontalScroll: true }}/>
        <button className='c-spin-btn' onClick={newChallengeAdri}>Nuevo Reto Aleatorio</button>
        <button className='c-punish-btn' onClick={handleClickPunish}>Ver Castigos y Premios Disponnibles</button>
      </div>
    </div>
  );
}
